<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Menu</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div>
                                <table id="example" class="display" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">Nama Provinsi</th>
                                          <th colspan="2">PM PTSP Pemda</th>
                                          <th rowspan="2">Pemangku Kepentingan Organisasi Pengusaha</th>
                                          <th rowspan="2">Total</th>
                                          <!-- <th colspan="3">Contact</th> -->
                                      </tr>
                                      <tr>
                                          <th style="text-align:center;">PM PTSP Pemda</th>
                                          <th>PPB Pemda</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td>{{row.daerah}}</td>
                                          <td>
                                           <button> {{row.total_pm}}</button></td>
                                          <td>{{row.total_ppb}}</td>
                                          <td>{{row.total_hipmi}}</td>
                                          <td>{{row.total_skor}}</td>
                                          <!-- <td>t.nixon@datatables.net</td> -->
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
// import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
  name: "dashboard",
  data() {
      return {
        test:'hai',
        urlpenilaian: localStorage.getItem('baseapipenilaian'),
        totskor:[],
        search: '',
        headers: [
            {
            text: 'Nama',
            align: 'left',
            sortable: false,
            value: 'name',
            },
            { text: 'Ket', value: 'ket' },
            { text: 'Icon', value: 'icon' },
            { text: 'Aksi', value: '' },
            
        ],
      }
  },
  components: {
    // AdvancedTableWidget2,
    // AdvancedTableWidget3,
    // MixedWidget1,
    // ListWidget1,
    // ListWidget3,
    // ListWidget4,
    // ListWidget8,
    // ListWidget9,
    // StatsWidget7,
    // StatsWidget12,
    // StatsWidget13
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "roles" }]);
    this.loaddata()
    // if (localStorage.getItem('id_token')) {
    //     // The page was just reloaded. Clear the value from local storage
    //     // so that it will reload the next time this page is visited.
    //     this.getuser(this.user);
    //     // localStorage.removeItem('reloaded');
    // } else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('id_token', '1');
    //     location.reload();
    // }
    // $(document).ready(function() {
    //   $('#example').DataTable();
    // });
  },
  methods: {
    loaddata(){
            axios.get(this.urlpenilaian+"/total_skor_pemda")
            .then(response => {
                this.totskor = response.data.data;
                this.initDatatable();
                // this.idpenilaian=response.data.data.id;
                console.log(response.data.data)
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });

            
    },
    initDatatable() {
                setTimeout(() => {
                $('#example').DataTable({
                    "pagingType": "full_numbers",
                    "lengthMenu": [
                        [10, 25, 50, -1],
                        [10, 25, 50, "All"]
                    ],
                    order: [[0, 'asc'], [3, 'desc']],
                    responsive: true,
                    destroy: true,
                    retrieve: true,
                    autoFill: true,
                    colReorder: true,

                });
               }, 300)
            },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
